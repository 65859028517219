import Alpine from 'alpinejs';
import { menu } from '../store/menu';

(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {

  menu()

});

Alpine.start();

